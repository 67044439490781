
import { React, useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import SideMenu from "../../component/side-menu/side-menu";
import Header from '../../component/header/header'
import CreatableSelect from 'react-select/creatable';
import 'react-datepicker/dist/react-datepicker.css';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDateTimePicker } from "@mui/x-date-pickers";
import apiClient from "../../instance/api-client";
import RichTextEditor from "../../component/rich-text-editor/rich-text-editor";
import dayjs from "dayjs";
import UploadFilePreview from "../../component/upload-file-preview/upload-file-preview";
import processMultiCreatableSelect from "../../function/process-multi-creatable-select";
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Select from "react-select"

export default function NewPost() {
  const userRole = useSelector(state => state.user.role)
  // const { id } = prop
  const { id } = useParams()
  const [alert, setAlert] = useState({
    show: false,
    message: '',
    status: false,
  })
  const [original, setOriginal] = useState({
    // hashTags: [],
    cateogry: []
  })

  const [content, setContent] = useState({
    title: '',
    subTitle: '',
    permLink: '',
    // slug: '',
    status: 'publish',
    schedulePost: new Date(),
    hashTag: [],
    categories: [],
    content: '',
    image: {
      id: null,
      filename: null
    },
    featuredImageId: null,
    metaTitle: null,
    metaKeyword: null,
    metaDescription: null,
  })

  useEffect(() => {
    if (id) {
      //load content
      loadPost(id)
    }
    loadCategories()
    // loadHashTags()
  }, [])

  const handleRichEditContentChange = (content) => {
    setContent((prev) => ({
      ...prev,
      content: content
    }));
  };

  const loadPost = async (id) => {

    try {
      const response = await apiClient.get('admin/contents/' + id)

      if (response.status === 200) {
        setContent((prev) => ({
          ...prev,
          ...response.data,
          categories: response.data.categories.flatMap((object) => { return { ...object, label: object.name, value: object.id } }),
          // hashTag: response.data.hashTag.flatMap((object) => { return { ...object, label: object.name, value: object.id } })
        }));

      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }

  };

  const loadCategories = async () => {
    try {
      const response = await apiClient.get('/categories', { params: { take: 99999 } })

      if (response.status === 200) {
        setOriginal((prev) => ({
          ...prev,
          cateogry: response.data.data.flatMap((object) => { return { ...object, label: object.name, value: object.id } })
        }))
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  // const loadHashTags = async () => {
  //   try {
  //     const response = await apiClient.get('/hash-tags')

  //     if (response.status === 200) {
  //       setOriginal((prev) => ({
  //         ...prev,
  //         hashTags: response.data.flatMap((object) => { return { ...object, label: object.name, value: object.id } })
  //       }))

  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }

  // const handleHashTagOnChange = (Tags) => {
  //   setContent((prev) => ({
  //     ...prev,
  //     hashTag: Tags
  //   }));
  // }

  const handleContentCategoryOnChange = (Category) => {
    setContent((prev) => ({
      ...prev,
      categories: Category
    }));
  }

  const handleDateTimeChange = date => {
    // setSelectedDate(date.$d.toISOString());
    setContent((prev) => ({
      ...prev,
      schedulePost: date.$d.toISOString()
    }));
  };

  const handleInputOnChange = (event) => {
    const id = event.target.id;
    
    setContent((prev) => ({
      ...prev,
      [id]: id==='permLink' ? event.target.value.replace(' ','-') : event.target.value
    }));
  }

  // const handleContentStatusOnChange = (event) => {
  //   setContent((prev) => ({
  //     ...prev,
  //     status: event.target.value
  //   }));
  // }

  const handleOnFeaturedImageChange = (filePath) => {

    setContent((prev) => ({
      ...prev,
      image: {
        id: filePath.id,
        filename: filePath.filename
      },
      featuredImageId: filePath.id
    }));
  }

  const processSaveContent = async () => {

    // const hashTagResult = await processHashTags()
    const categoriesResult = await processCategory()

    const newContent = {
      ...content,
      // hashTag: hashTagResult.full,
      categories: categoriesResult.full,
    }

    setContent({
      ...newContent,
      categories: categoriesResult.full,
      // hashTag: hashTagResult.full,
    });

    let postResultMessage = ""
    try {
      let response
      if (id !== undefined && id !== '') {
        postResultMessage = "Success Update Post"
        response = await apiClient.patch("admin/contents/" + id, newContent)
      } else {
        postResultMessage = "Success Created Post"
        response = await apiClient.post("admin/contents", newContent)
      }

      if (response.data.statusCode === 200) {
        setAlert({ message: postResultMessage, show: true, status: true })
      }

    } catch (error) {
      console.info(error)
      console.error("Error fetching data:", error.response.data.message);
      setAlert({ message: error.response.data.message, show: true, status: false })
    }
  }

  const handleClose = () => {
    setAlert({ message: '', show: false })
  }

  // const processHashTags = async () => {
  //   return processMultiCreatableSelect(content.hashTag, 'Tags')
  // }

  const processCategory = async () => {
    return processMultiCreatableSelect(content.categories, "Category")
  }

  return (
    <div className="g-sidenav-show">
      <SideMenu />
      <Header />
      <div className="main-content" id="panel">
        <div className="container-fluid2 pt-3 row removable">
          <div className="row my-4">
            <div className="col-lg-8 col-md-6 mb-md-0 mb-4">
              <div className="card">
                <div className="card-header pb-0"></div>

                {/* <!--Card-Body-Content_HERE--> */}
                <div className="card-body card-body2">
                  <form>
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group2">
                            <label className="form-control-label" htmlFor="input-city">Featured Image</label>

                            {/* <!-- Upload Area --> */}
                            <UploadFilePreview featuredImage={content.image} onChange={handleOnFeaturedImageChange} />
                            {/* <!-- End Upload Area --> */}
                          </div>

                          <div className="form-group2">
                            <label className="form-control-label" htmlFor="input-city">Title</label>
                            <input id="title" className="form-control2" placeholder="Add Title" value={content.title} type="text" onChange={handleInputOnChange} />
                          </div>
                          <div className="form-group2">
                            <label className="form-control-label" htmlFor="input-city">Sub Title</label>
                            <input id="subTitle" className="form-control2" placeholder="Add Sub-Title" value={content.subTitle} type="text" onChange={handleInputOnChange} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group2">
                            <label className="form-control-label" htmlFor="input-city">Add Permalinks</label>
                            <input type="text" id="permLink" className="form-control2" placeholder="your-link-parameter-here" value={content.permLink} onChange={handleInputOnChange} />
                          </div>
                        </div>
                        {/* <div className="col-lg-6">
                          <div className="form-group2">
                            <label className="form-control-label" htmlFor="input-country">Add Slug</label>
                            <input type="text" id="slug" className="form-control2" placeholder="Slug (Can be in Chinese Language)" value={content.slug} onChange={handleInputOnChange} />
                          </div>
                        </div> */}
                      </div>
                      <span style={{ marginLeft: '40px' }}>Meta Use</span>
                      <div className="col-lg-12">
                        <div className="form-group2">
                          <label className="form-control-label" htmlFor="input-city">Meta Title</label>
                          <input id="metaTitle" className="form-control2" placeholder="Add Title" value={content.metaTitle} type="text" onChange={handleInputOnChange} />
                        </div>
                        <div className="form-group2">
                          <label className="form-control-label" htmlFor="input-city">Meta Keyword</label>
                          <input id="metaKeyword" className="form-control2" placeholder="Add Keyword" value={content.metaKeyword} type="text" onChange={handleInputOnChange} />
                          <span style={{ fontSize: '11px', backgroundColor: 'rgba(255,255,0,0.5)' }}>*sample : 满分足球,足球新闻,足球动态,英超积分榜,球队排名,中超积分榜,积分榜 each words end with comma</span>
                        </div>
                        <div className="form-group2">
                          <label className="form-control-label" htmlFor="input-city">Meta Description</label>
                          <input id="metaDescription" className="form-control2" placeholder="Add Description" value={content.metaDescription} type="text" onChange={handleInputOnChange} />
                        </div>
                      </div>

                    </div>

                    {/* <!--<hr className="horizontal dark my-4">--> */}

                    {/* <!-- Rich-Text-Editor --> */}
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group2">
                            <div className="col-sm-6">
                              <label className="mt-4">Content</label>
                              <p className="text-xs form-text text-muted ms-1 d-inline">(Start writing your posts here...)</p>
                              <RichTextEditor content={content.content} onChange={handleRichEditContentChange} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div id="profile" className="card card-body h-101">
                <div className="card-header pb-0">
                  <h6>Publish Options</h6>
                  {/* <p className="text-sm">
                    <i className="fa fa-arrow-up text-success" aria-hidden="true"></i>
                    <span className="font-weight-bold">25%</span> this month&apos;s posts
                  </p> */}
                </div>

                <div id="basic-info">
                  <div className="card-body d-sm-flex pt-0">
                    <div className="row">
                      <div className="col-sm-5 col-7 d-flex align-items-center mb-sm-0 mb-4">
                        <select className="form-control3 form-control-sm" id="status" onChange={handleInputOnChange} value={content.status}>
                          <option value="publish">Publish</option>
                          <option value="unpublish">Unpublished</option>
                          <option value="schedule">Scheduled</option>
                        </select>
                        <button className="btn mb-0 bg-gradient-success btn-md null null mb-0 ms-2" type="button" name="button" onClick={processSaveContent}>Apply</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div hidden={content.status !== "schedule"}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDateTimePicker orientation="portrait"
                    defaultValue={dayjs(content.schedulePost)}
                    value={dayjs(content.schedulePost)}
                    onChange={handleDateTimeChange} />
                </LocalizationProvider> 
              </div>

              <br />

              <div id="profile" className="card card-body">
                <div className="justify-content-center align-items-center">
                  <div className="col-sm-auto col-4">
                    <div className="select2-container">
                      <div className="h-101">
                        <div className="p-3 pb-0 card-header">
                          <h6 className="mb-0">Category</h6>
                        </div>
                        {userRole === "EDITOR" ? (<Select isMulti id="categories" isSearchable inputId="categories" options={original.cateogry} value={content.categories} onChange={handleContentCategoryOnChange} />) : (<CreatableSelect isMulti id="categories" inputId="categories" options={original.cateogry} value={content.categories} onChange={handleContentCategoryOnChange} />)}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              {/* <div id="profile" className="card card-body">
                <div className="justify-content-center align-items-center">
                  <div className="col-sm-auto col-4">
                    <div className="select2-container">
                      <div className="h-101">
                        <div className="p-3 pb-0 card-header">
                          <h6 className="mb-0">Tags</h6>
                        </div>
                        <CreatableSelect isMulti id="hashTag" inputId="hashTag" options={original.hashTags} value={content.hashTag} onChange={handleHashTagOnChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {alert.show && (<div className={`loopple-alert loopple-alert-dismissible loopple-fade loopple-position-fixed loopple-z-index-9999 loopple-top-2 loopple-mx-auto loopple-text-center loopple-right-0 loopple-left-0 loopple-w-50 ${alert.status ? 'bg-gradient-success' : 'bg-gradient-warning'}`} role="alert">
        <strong>{alert.message ? alert.message : 'You\'ve just published your scheduled posts!'}</strong>
        {/*<a class="loopple-btn loopple-btn-white loopple-ml-2" href="https://builder.creative-tim.com/builder/project-YCW2KXyKEB8VlvNDRwhrt6dlWrTAV5x9a2X" target="_blank">Editor</a>*/}
        <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true" onClick={handleClose}>✖</span></button>
      </div>)}

    </div >
  )
}


NewPost.propTypes = {
  userRole: PropTypes.string,
};
